import React from "react";
import { Link } from "react-router-dom";
import Artists from "./../../Frontend/Artists/index";

/**
 * @author
 * @function ArtistsPage
 **/

const ArtistsPage = (props) => {
  return (
    <React.Fragment>
      {/* artists */}
      <div className="row row--grid">
        {/* breadcrumb */}
        <div className="col-12">
          <ul className="breadcrumb" style={{ background: "transparent" }}>
            <li className="breadcrumb__item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb__item breadcrumb__item--active">
              Artists
            </li>
          </ul>
        </div>
        {/* end breadcrumb */}
        {/* title */}
        <div className="col-12">
          <div className="main__title main__title--page">
            <h1>Presenters</h1>
          </div>
        </div>
        {/* end title */}
      </div>
      <div className="row row--grid">
        <div className="col-12">
          {/* <div className="main__filter" style={{ display: "none" }}>
            <form action="#" className="main__filter-search">
              <input type="text" placeholder="Search..." />
              <button type="button">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M21.71,20.29,18,16.61A9,9,0,1,0,16.61,18l3.68,3.68a1,1,0,0,0,1.42,0A1,1,0,0,0,21.71,20.29ZM11,18a7,7,0,1,1,7-7A7,7,0,0,1,11,18Z" />
                </svg>
              </button>
            </form>
            <div className="main__filter-wrap">
              <select className="main__select" name="genres">
                <option value="all">All artists</option>
                <option value="legacy">Legacy artists</option>
                <option value="active">Active artists</option>
              </select>
              <select className="main__select" name="years">
                <option value="All genres">All genres</option>
                <option value={1}>Alternative</option>
                <option value={2}>Blues</option>
                <option value={3}>Classical</option>
                <option value={4}>Country</option>
                <option value={5}>Electronic</option>
                <option value={6}>Hip-Hop/Rap</option>
                <option value={7}>Indie</option>
                <option value={8}>Jazz</option>
                <option value={8}>Latino</option>
                <option value={8}>R&amp;B/Soul</option>
                <option value={8}>Rock</option>
              </select>
            </div>
            <div className="slider-radio">
              <input
                type="radio"
                name="grade"
                id="featured"
                defaultChecked="checked"
              />
              <label htmlFor="featured">Featured</label>
              <input type="radio" name="grade" id="popular" />
              <label htmlFor="popular">Popular</label>
              <input type="radio" name="grade" id="newest" />
              <label htmlFor="newest">Newest</label>
            </div>
          </div> */}
          <Artists title="no" />
          <button className="main__load" type="button">
            Load more
          </button>
        </div>
      </div>
      {/* end artists */}
      {/* end main content */}
    </React.Fragment>
  );
};

export default ArtistsPage;
