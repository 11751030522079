import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { data } from "./../../assets/js/function";
import BounceLoader from "react-spinners/BounceLoader";
/**
 * @author
 * @function Release
 **/

const Release = (props) => {
  //Category This Week ID = 1
  let dataM = data.apiData.whereCatagory(5);
  const history = useHistory();
  let [_data, setData] = useState(dataM);
  useEffect(() => {
    if (dataM) {
      setData(dataM);
      console.log("log 1", _data);
    } else {
      // setInterval(() => {
      dataM = data.apiData.whereCatagory(5);
      if (dataM) setData(dataM);
      console.log("log 2", _data);
      // }, 5000);
    }
  }, []);

  return (
    <section className="row row--grid">
      {/* title */}
      <div className="col-12">
        <div className="main__title">
          <h2>This Week</h2>
          <Link to="releases" className="main__link">
            See all{" "}
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M17.92,11.62a1,1,0,0,0-.21-.33l-5-5a1,1,0,0,0-1.42,1.42L14.59,11H7a1,1,0,0,0,0,2h7.59l-3.3,3.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l5-5a1,1,0,0,0,.21-.33A1,1,0,0,0,17.92,11.62Z" />
            </svg>
          </Link>
        </div>
      </div>
      {/* end title */}
      {_data ? (
        _data.map((value, key) => {
          return (
            <div
              className="col-6 col-sm-4 col-lg-2"
              onClick={() =>
                history.push(
                  "/this-week/" +
                    value.id +
                    "/" +
                    value.title.replace(/ /g, "-").toLowerCase()
                )
              }
              key={key}>
              <div className="album">
                <div className="album__cover">
                  <img src={value.image} alt="" />
                  <Link
                    to={
                      "/this-week/" +
                      value.id +
                      "/" +
                      value.title.replace(/ /g, "-").toLowerCase()
                    }>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="M18.54,9,8.88,3.46a3.42,3.42,0,0,0-5.13,3V17.58A3.42,3.42,0,0,0,7.17,21a3.43,3.43,0,0,0,1.71-.46L18.54,15a3.42,3.42,0,0,0,0-5.92Zm-1,4.19L7.88,18.81a1.44,1.44,0,0,1-1.42,0,1.42,1.42,0,0,1-.71-1.23V6.42a1.42,1.42,0,0,1,.71-1.23A1.51,1.51,0,0,1,7.17,5a1.54,1.54,0,0,1,.71.19l9.66,5.58a1.42,1.42,0,0,1,0,2.46Z" />
                    </svg>
                  </Link>
                </div>
                {/* <div className="album__title">
                  <h3>
                    <Link to="release">{value.title}</Link>
                  </h3>
                  <span>{new Date(value.created_at).toLocaleTimeString()}</span>
                </div> */}
              </div>
            </div>
          );
        })
      ) : (
        <div className="col-12">
          <div className="w-100 p-5">
            <center>
              <BounceLoader color="#ffffff" loading="true" size="50" />
            </center>
          </div>
        </div>
      )}
    </section>
  );
};

export default Release;
