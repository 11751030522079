import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { data } from "./../../assets/js/function";
import BounceLoader from "react-spinners/BounceLoader";
import Release from "./../../Frontend/Release/index";
/**
 * @author
 * @function ReleasePage
 **/

const ReleasePage = (props) => {
  let dataM = data.apiData.whereCatagory(5);
  const history = useHistory();
  let [_data, setData] = useState(dataM);
  useEffect(() => {
    if (dataM) {
      setData(dataM);
      console.log("log 1", _data);
    } else {
      // setInterval(() => {
      dataM = data.apiData.whereCatagory(5);
      if (dataM) setData(dataM);
      console.log("log 2", _data);
      // }, 5000);
    }
  }, []);
  return (
    <div className="row row--grid">
      {/* breadcrumb */}
      <div className="col-12">
        <ul className="breadcrumb" style={{ background: "transparent" }}>
          <li className="breadcrumb__item">
            <Link to="index">Home</Link>
          </li>
          <li className="breadcrumb__item">
            <Link to="releases">Releases</Link>
          </li>
          <li className="breadcrumb__item breadcrumb__item--active">Release</li>
        </ul>
      </div>
      {/* end breadcrumb */}
      <div>
        <Release />
      </div>
    </div>
  );
};

export default ReleasePage;
