import React from "react";
import Slider from "./../../Slider/index";
import Release from "./../../Release/index";
import Events from "./../../Events/index";
import Artists from "./../../Artists/index";
import Categories from "./../../Categories/index";
import PodcastStandard from "./../../PodcastStandard/index";
/**
 * @author
 * @function HomeBody
 **/

const HomeBody = (props) => {
  return (
    <React.Fragment>
      {/* slider */}
      <Slider />
      {/* end slider */}
      {/* releases */}
      <Release />
      {/* end releases */}
      {/* events */}
      <Events />
      {/* end events */}
      {/* articts */}
      <Artists />
      {/* end articts */}
      {/* podcasts */}
      <PodcastStandard />
      {/* end podcasts */}
    </React.Fragment>
  );
};

export default HomeBody;
