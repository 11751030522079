import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { data } from "./../../../assets/js/function";
import BounceLoader from "react-spinners/BounceLoader";
/**
 * @author
 * @function TopSingles
 **/

const TopSingles = (props) => {
  let id = props.match.params.id;
  let [_data, setData] = useState();

  useEffect(() => {
    let timeout = setTimeout(() => {
      let dataM = data.apiData.website_show_data(id);
      if (dataM) {
        setData(dataM);
      } else {
        let interval = setInterval(() => {
          dataM = data.apiData.website_show_data(id);

          if (dataM) {
            setData(dataM);
            clearInterval(interval);
          }
        }, 3000);
      }
      clearTimeout(timeout);
    }, 3000);
  }, []);

  useEffect(() => {
    setData(_data);
  }, [_data]);
  return (
    <div className="col-12 col-md-6 col-xl-4">
      {_data ? (
        <div className="row row--grid">
          {/* title */}
          <div className="col-12">
            <div className="main__title">
              <h2>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M21.65,2.24a1,1,0,0,0-.8-.23l-13,2A1,1,0,0,0,7,5V15.35A3.45,3.45,0,0,0,5.5,15,3.5,3.5,0,1,0,9,18.5V10.86L20,9.17v4.18A3.45,3.45,0,0,0,18.5,13,3.5,3.5,0,1,0,22,16.5V3A1,1,0,0,0,21.65,2.24ZM5.5,20A1.5,1.5,0,1,1,7,18.5,1.5,1.5,0,0,1,5.5,20Zm13-2A1.5,1.5,0,1,1,20,16.5,1.5,1.5,0,0,1,18.5,18ZM20,7.14,9,8.83v-3L20,4.17Z" />
                </svg>
                <Link to="#">Shows</Link>
              </h2>
            </div>
          </div>
          {/* end title */}
          <div className="col-12">
            <ul className="main__list">
              {_data.map((v, k) => {
                return (
                  <li className="single-item" key={k}>
                    <span className="single-item__number">{k + 1}</span>
                    <span className="single-item__rate">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24">
                        <path d="M12.71,12.54a1,1,0,0,0-1.42,0l-3,3A1,1,0,0,0,9.71,17L12,14.66,14.29,17a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Zm-3-1.08L12,9.16l2.29,2.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42l-3-3a1,1,0,0,0-1.42,0l-3,3a1,1,0,0,0,1.42,1.42Z" />
                      </svg>{" "}
                      {k + 1}
                    </span>
                    <div className="single-item__title">
                      <h4>
                        <Link to="#">{v.title}</Link>
                      </h4>
                      <span>
                        <Link to="#">
                          {new Date(v.created_at).toLocaleTimeString()}
                        </Link>
                      </span>
                    </div>
                    <span className="single-item__time">
                      <audio src={v.audio} controls></audio>
                    </span>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      ) : (
        <center style={{ width: "100%" }}>
          <BounceLoader color="#ffffff" loading="true" size="50" />
        </center>
      )}
    </div>
  );
};

export default TopSingles;
