import React, { useEffect, useState } from "react";
import $ from "jquery";
import { data } from "./../../assets/js/function";
/**
 * @author
 * @function Player
 **/

const Player = (props) => {
  let [nowplaying, setNowPlaying] = useState("...");
  let [logo, setLogo] = useState();

  useEffect(() => {
    let timeout = setTimeout(() => {
      let dataM = data.apiData.settings();
      if (dataM) {
        setLogo(dataM);
      } else {
        let interval = setInterval(() => {
          if (dataM) {
            setLogo(dataM);
            clearInterval(interval);
          }
        }, 3000);
      }
      clearTimeout(timeout);
    }, 3000);
  }, []);

  let changeTitle = () => {
    if (!logo) return;
    $.ajax({
      type: "GET",
      url: logo.nowplayin_link + "&_=" + new Date().getTime(),
      success: function (response) {
        if (response == null || response == "") return console.log("Null");
        let d = JSON.parse(response);
        setNowPlaying(d.nowplaying);
      }
    });
  };

  setInterval(() => {
    changeTitle();
  }, 10000);
  useEffect(() => {
    setNowPlaying(nowplaying);
    changeTitle();
  }, [nowplaying]);
  return (
    <React.Fragment>
      <div className="player">
        <div className="player__cover">
          {logo ? <img src={logo.artwork_link} alt="" /> : ""}
        </div>
        <div className="player__content">
          <span className="player__track">
            <span className="player__artist">{nowplaying ?? "..."}</span>
          </span>
          {logo ? (
            <audio src={logo.stream_link} id="audio" autoplay controls />
          ) : (
            ""
          )}
        </div>
      </div>
      {logo ? (
        <button className="player__btn" type="button">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M21.65,2.24a1,1,0,0,0-.8-.23l-13,2A1,1,0,0,0,7,5V15.35A3.45,3.45,0,0,0,5.5,15,3.5,3.5,0,1,0,9,18.5V10.86L20,9.17v4.18A3.45,3.45,0,0,0,18.5,13,3.5,3.5,0,1,0,22,16.5V3A1,1,0,0,0,21.65,2.24ZM5.5,20A1.5,1.5,0,1,1,7,18.5,1.5,1.5,0,0,1,5.5,20Zm13-2A1.5,1.5,0,1,1,20,16.5,1.5,1.5,0,0,1,18.5,18ZM20,7.14,9,8.83v-3L20,4.17Z" />
          </svg>{" "}
          Player
        </button>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default Player;
