import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { data } from "./../../assets/js/function";
import BounceLoader from "react-spinners/BounceLoader";

/**
 * @author
 * @function Artists
 **/

const Artists = (props) => {
  let [artists, setartists] = useState();
  useEffect(() => {
    let timeout = setTimeout(() => {
      let dataM = data.apiData.website_artists();
      if (dataM) {
        setartists(dataM);
      } else {
        let interval = setInterval(() => {
          if (dataM) {
            setartists(dataM);
            clearInterval(interval);
          }
        }, 3000);
      }
      clearTimeout(timeout);
    }, 3000);
  }, []);

  useEffect(() => {
    setartists(artists);
  }, [artists]);
  return (
    <section className="row row--grid">
      {/* title */}
      {artists ? (
        <>
          {" "}
          <div className="col-12">
            {props.title == "no" ? (
              ""
            ) : (
              <div className="main__title">
                <h2>Presenters</h2>
                <Link to="/artists" className="main__link">
                  See all{" "}
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M17.92,11.62a1,1,0,0,0-.21-.33l-5-5a1,1,0,0,0-1.42,1.42L14.59,11H7a1,1,0,0,0,0,2h7.59l-3.3,3.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l5-5a1,1,0,0,0,.21-.33A1,1,0,0,0,17.92,11.62Z" />
                  </svg>
                </Link>
              </div>
            )}
          </div>
          {/* end title */}
          <div className="col-12">
            <div className="main__carousel-wrap">
              <div
                className="main__carousel main__carousel--artists owl-carousel"
                id="artists">
                {artists.map((v, k) => {
                  return (
                    <Link
                      to={
                        "/artist/" +
                        v.id +
                        "/" +
                        v.name.replace(/ /g, "-").toLowerCase()
                      }
                      className="artist"
                      key={k}>
                      {" "}
                      <div className="artist__cover">
                        <img src={v.image} alt="" />
                      </div>
                      <h3 className="artist__title">{v.name}</h3>{" "}
                    </Link>
                  );
                })}
              </div>
            </div>
          </div>
        </>
      ) : (
        <center style={{ width: "100%" }}>
          <BounceLoader color="#ffffff" loading="true" size="50" />
        </center>
      )}
    </section>
  );
};

export default Artists;
