import React, { useEffect } from "react";
import Footer from "../Footer";
import HeaderHomePage from "../Header";
import Logo from "../Logo";
import Modal from "../Modal";
import Player from "../Player";
import Sidebar from "../Sidebar";
import HomeBody from "./HomeBody/index";
import { Switch, Route, useLocation } from "react-router-dom";
import ReleasePage from "./../../Pages/Release/index";
import HomeLoader from "../../Loader/Home";
import EventsPage from "./../../Pages/Events/index";
import PodcastsPage from "./../../Pages/PodcastsPage/index";
import ProfilePage from "./../../Pages/Profile/index";
import AboutPage from "../../Pages/AboutPage/index";
import ContactPage from "./../../Pages/ContactPage/index";
import ArtistsPage from "./../../Pages/ArtistsPage/index";
import ArtistPage from "./../../Pages/ArtistPage/index";
import SignupPage from "./../../Pages/Signup/index";
import SigninPage from "./../../Pages/Signin/index";
import AdminPage from "./../../Pages/Admin/index";
import ThisWeek from "./../../Pages/ThisWeek/index";
import TopSingles from "./../Categories/TopSingles/index";
/**
 * @author
 * @function Homepage
 **/

const Homepage = (props) => {
  let location = useLocation();
  let newp = location.pathname;
  useEffect(() => {}, [newp]);
  return (
    <>
      {/* header */}
      <HeaderHomePage />
      {/* end header */}
      {/* sidebar */}
      <div className="sidebar">
        {/* sidebar logo */}
        <Logo />
        {/* end sidebar logo */}
        {/* sidebar nav */}
        <Sidebar />
        {/* end sidebar nav */}
      </div>
      {/* end sidebar */}
      {/* player */}
      <Player />
      {/* end player */}
      {/* main content */}
      <main className="main">
        <div className="container-fluid">
          <Switch>
            <HomeLoader>
              <Route exact path="/" component={HomeBody} />
              <Route path="/artists" component={ArtistsPage} />
              <Route path="/releases" component={ReleasePage} />
              <Route path="/events" component={EventsPage} />
              <Route path="/podcasts" component={PodcastsPage} />
              <Route path="/profile" component={ProfilePage} />
              <Route path="/about" component={AboutPage} />
              <Route path="/contacts" component={ContactPage} />
              <Route path="/signup" component={SignupPage} />
              <Route path="/signin" component={SigninPage} />
              <Route path="/admin" component={AdminPage} />
              <Route path="/this-week/:id/:title" component={ThisWeek} />
              <Route path="/show/:id/:title" component={TopSingles} />
              <Route path="/artist/:id/:name" component={ArtistPage} />
            </HomeLoader>
          </Switch>
        </div>
      </main>
      {/* end main content */}
      {/* footer */}
      <Footer />
      {/* end footer */}
      {/* modal */}
      <Modal />
      {/* end modal */}
    </>
  );
};

export default Homepage;
