import React, { useEffect, useState } from "react";
import BounceLoader from "react-spinners/BounceLoader";
import { data } from "./../../assets/js/function";

/**
 * @author
 * @function Logo
 **/

const Logo = (props) => {
  let [logo, setLogo] = useState();
  useEffect(() => {
    let timeout = setTimeout(() => {
      let dataM = data.apiData.settings();
      if (dataM) {
        setLogo(dataM);
        window.document.title = dataM.name;
      } else {
        let interval = setInterval(() => {
          if (dataM) {
            setLogo(dataM);
            window.document.title = dataM.name ?? "Online Radio";
            clearInterval(interval);
          }
        }, 3000);
      }
      clearTimeout(timeout);
    }, 3000);
  }, []);
  return (
    <div className="sidebar__logo">
      {logo ? (
        <img src={logo.photo} alt="" />
      ) : (
        <center style={{ width: "100%" }}>
          <BounceLoader color="#ffffff" loading="true" size="20" />
        </center>
      )}
    </div>
  );
};

export default Logo;
