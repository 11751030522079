import React from "react";
import { Link } from "react-router-dom";
import PodcastStandard from "./../../Frontend/PodcastStandard/index";
/**
 * @author
 * @function PodcastsPage
 **/

const PodcastsPage = (props) => {
  return (
    <div>
      <div className="row row--grid">
        {/* breadcrumb */}
        <div className="col-12">
          <ul className="breadcrumb" style={{ background: "transparent" }}>
            <li className="breadcrumb__item">
              <Link to="index">Home</Link>
            </li>
            <li className="breadcrumb__item breadcrumb__item--active">Shows</li>
          </ul>
        </div>
        {/* end breadcrumb */}
        {/* title */}
        <div className="col-12">
          <div className="main__title main__title--page">
            <h1>Shows</h1>
          </div>
        </div>
        {/* end title */}
      </div>
      <div className="row row--grid">
        <div className="col-12">
          {/* <div className="main__filter">
            <form action="#" className="main__filter-search">
              <input type="text" placeholder="Search..." />
              <button type="button">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M21.71,20.29,18,16.61A9,9,0,1,0,16.61,18l3.68,3.68a1,1,0,0,0,1.42,0A1,1,0,0,0,21.71,20.29ZM11,18a7,7,0,1,1,7-7A7,7,0,0,1,11,18Z" />
                </svg>
              </button>
            </form>
            <div className="main__filter-wrap">
              <select className="main__select" name="genres">
                <option value="popular">Popular</option>
                <option value="live">Live</option>
                <option value="newest">Newest</option>
              </select>
            </div>
            <div className="slider-radio">
              <input
                type="radio"
                name="grade"
                id="live"
                defaultChecked="checked"
              />
              <label htmlFor="live">Live</label>
              <input type="radio" name="grade" id="popular" />
              <label htmlFor="popular">Popular</label>
              <input type="radio" name="grade" id="newest" />
              <label htmlFor="newest">Newest</label>
            </div>
          </div> */}
          <PodcastStandard title="no" />
          <div className="row row--grid">
            <div className="col-12">
              <button className="main__load" type="button">
                Load more
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PodcastsPage;
