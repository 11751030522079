import React from "react";
import { Link } from "react-router-dom";
/**
 * @author
 * @function AboutPage
 **/

const AboutPage = (props) => {
  return (
    <div>
      <div className="row row--grid">
        {/* breadcrumb */}
        <div className="col-12">
          <ul className="breadcrumb" style={{ background: "transparent" }}>
            <li className="breadcrumb__item">
              <Link to="index.html">Home</Link>
            </li>
            <li className="breadcrumb__item breadcrumb__item--active">About</li>
          </ul>
        </div>
        {/* end breadcrumb */}
        {/* title */}
        <div className="col-12">
          <div className="main__title">
            <h1>Online music HTML Template</h1>
            <p>
              Many desktop publishing packages and <Link to="#">web page</Link>{" "}
              editors now use Lorem Ipsum as their default model text, and a
              search for 'lorem ipsum' will uncover many web sites still in
              their infancy. Various versions have evolved over the years,
              sometimes by accident, sometimes on purpose (injected humour and
              the like).
            </p>
            <p>
              All the Lorem Ipsum generators on the <b>Internet</b> tend to
              repeat predefined chunks as necessary, making this the first true
              generator on the Internet. It uses a dictionary of over 200 Latin
              words, combined with a handful of model sentence structures, to
              generate Lorem Ipsum which looks reasonable. The generated Lorem
              Ipsum is therefore always free from repetition, injected humour,
              or non-characteristic words etc.
            </p>
          </div>
        </div>
        {/* end title */}
        <div className="col-12 col-lg-4">
          <div className="step">
            <span className="step__number">01</span>
            <h3 className="step__title">Create an account</h3>
            <p className="step__text">
              It to make a type specimen book. It has survived not only five
              centuries, but also the leap into electronic typesetting,
              remaining
            </p>
          </div>
        </div>
        <div className="col-12 col-lg-4">
          <div className="step">
            <span className="step__number">02</span>
            <h3 className="step__title">Choose your plan</h3>
            <p className="step__text">
              All the Lorem Ipsum generators on the Internet tend to repeat
              predefined chunks as necessary, making this the first
            </p>
          </div>
        </div>
        <div className="col-12 col-lg-4">
          <div className="step">
            <span className="step__number">03</span>
            <h3 className="step__title">Enjoy Volna music</h3>
            <p className="step__text">
              It to make a type specimen book. It has survived not only five
              centuries, but also the leap into electronic typesetting
            </p>
          </div>
        </div>
        <div className="col-12">
          <Link to="signup.html" className="main__load">
            Sign up
          </Link>
        </div>
      </div>
      <div className="row row--grid">
        {/* title */}
        <div className="col-12">
          <div className="main__title">
            <h2>Subscribe features</h2>
            <p>
              Many desktop publishing packages and <Link to="#">web page</Link>{" "}
              editors now use Lorem Ipsum as their default model text, and a
              search for 'lorem ipsum' will uncover many web sites still in
              their infancy. Various versions have evolved over the years,
              sometimes by accident, sometimes on purpose (injected humour and
              the like).
            </p>
          </div>
        </div>
        {/* end title */}
        <div className="col-12 col-md-6 col-lg-4 col-xl-3">
          <div className="feature">
            <span className="feature__icon feature__icon--blue">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M19,7H18V6a3,3,0,0,0-3-3H5A3,3,0,0,0,2,6H2V18a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V10A3,3,0,0,0,19,7ZM5,5H15a1,1,0,0,1,1,1V7H5A1,1,0,0,1,5,5ZM20,15H19a1,1,0,0,1,0-2h1Zm0-4H19a3,3,0,0,0,0,6h1v1a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V8.83A3,3,0,0,0,5,9H19a1,1,0,0,1,1,1Z" />
              </svg>
            </span>
            <h3 className="feature__title">Instant conclusion</h3>
            <p className="feature__text">
              It to make a type specimen book. It has survived not only five
              centuries, but also the leap into electronic typesetting,
              remaining
            </p>
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-4 col-xl-3">
          <div className="feature">
            <span className="feature__icon feature__icon--red">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M9,10a1,1,0,0,0-1,1v2a1,1,0,0,0,2,0V11A1,1,0,0,0,9,10Zm12,1a1,1,0,0,0,1-1V6a1,1,0,0,0-1-1H3A1,1,0,0,0,2,6v4a1,1,0,0,0,1,1,1,1,0,0,1,0,2,1,1,0,0,0-1,1v4a1,1,0,0,0,1,1H21a1,1,0,0,0,1-1V14a1,1,0,0,0-1-1,1,1,0,0,1,0-2ZM20,9.18a3,3,0,0,0,0,5.64V17H10a1,1,0,0,0-2,0H4V14.82A3,3,0,0,0,4,9.18V7H8a1,1,0,0,0,2,0H20Z" />
              </svg>
            </span>
            <h3 className="feature__title">Choose your plan</h3>
            <p className="feature__text">
              If you are going to use a passage of Lorem Ipsum, you need to be
              sure there isn't anything embarrassing hidden in the middle of
              text
            </p>
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-4 col-xl-3">
          <div className="feature">
            <span className="feature__icon feature__icon--purple">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M20,2H10A3,3,0,0,0,7,5v7a3,3,0,0,0,3,3H20a3,3,0,0,0,3-3V5A3,3,0,0,0,20,2Zm1,10a1,1,0,0,1-1,1H10a1,1,0,0,1-1-1V5a1,1,0,0,1,1-1H20a1,1,0,0,1,1,1ZM17.5,8a1.49,1.49,0,0,0-1,.39,1.5,1.5,0,1,0,0,2.22A1.5,1.5,0,1,0,17.5,8ZM16,17a1,1,0,0,0-1,1v1a1,1,0,0,1-1,1H4a1,1,0,0,1-1-1V15H4a1,1,0,0,0,0-2H3V12a1,1,0,0,1,1-1A1,1,0,0,0,4,9a3,3,0,0,0-3,3v7a3,3,0,0,0,3,3H14a3,3,0,0,0,3-3V18A1,1,0,0,0,16,17ZM6,18H7a1,1,0,0,0,0-2H6a1,1,0,0,0,0,2Z" />
              </svg>
            </span>
            <h3 className="feature__title">Create an account</h3>
            <p className="feature__text">
              It has survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially
            </p>
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-4 col-xl-3">
          <div className="feature">
            <span className="feature__icon">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M21.65,2.24a1,1,0,0,0-.8-.23l-13,2A1,1,0,0,0,7,5V15.35A3.45,3.45,0,0,0,5.5,15,3.5,3.5,0,1,0,9,18.5V10.86L20,9.17v4.18A3.45,3.45,0,0,0,18.5,13,3.5,3.5,0,1,0,22,16.5V3A1,1,0,0,0,21.65,2.24ZM5.5,20A1.5,1.5,0,1,1,7,18.5,1.5,1.5,0,0,1,5.5,20Zm13-2A1.5,1.5,0,1,1,20,16.5,1.5,1.5,0,0,1,18.5,18ZM20,7.14,9,8.83v-3L20,4.17Z" />
              </svg>
            </span>
            <h3 className="feature__title">Enjoy Blast music</h3>
            <p className="feature__text">
              Various versions have evolved over the years, sometimes by
              accident, sometimes on purpose
            </p>
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-4 col-xl-3">
          <div className="feature">
            <span className="feature__icon">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M20,8.18V3a1,1,0,0,0-2,0V8.18a3,3,0,0,0,0,5.64V21a1,1,0,0,0,2,0V13.82a3,3,0,0,0,0-5.64ZM19,12a1,1,0,1,1,1-1A1,1,0,0,1,19,12Zm-6,2.18V3a1,1,0,0,0-2,0V14.18a3,3,0,0,0,0,5.64V21a1,1,0,0,0,2,0V19.82a3,3,0,0,0,0-5.64ZM12,18a1,1,0,1,1,1-1A1,1,0,0,1,12,18ZM6,6.18V3A1,1,0,0,0,4,3V6.18a3,3,0,0,0,0,5.64V21a1,1,0,0,0,2,0V11.82A3,3,0,0,0,6,6.18ZM5,10A1,1,0,1,1,6,9,1,1,0,0,1,5,10Z" />
              </svg>
            </span>
            <h3 className="feature__title">Choose your plan</h3>
            <p className="feature__text">
              It has survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially
            </p>
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-4 col-xl-3">
          <div className="feature">
            <span className="feature__icon">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M19.63,3.65a1,1,0,0,0-.84-.2,8,8,0,0,1-6.22-1.27,1,1,0,0,0-1.14,0A8,8,0,0,1,5.21,3.45a1,1,0,0,0-.84.2A1,1,0,0,0,4,4.43v7.45a9,9,0,0,0,3.77,7.33l3.65,2.6a1,1,0,0,0,1.16,0l3.65-2.6A9,9,0,0,0,20,11.88V4.43A1,1,0,0,0,19.63,3.65ZM18,11.88a7,7,0,0,1-2.93,5.7L12,19.77,8.93,17.58A7,7,0,0,1,6,11.88V5.58a10,10,0,0,0,6-1.39,10,10,0,0,0,6,1.39ZM13.54,9.59l-2.69,2.7-.89-.9a1,1,0,0,0-1.42,1.42l1.6,1.6a1,1,0,0,0,1.42,0L15,11a1,1,0,0,0-1.42-1.42Z" />
              </svg>
            </span>
            <h3 className="feature__title">No contracts, no risk</h3>
            <p className="feature__text">
              Various versions have evolved over the years, sometimes by
              accident, sometimes on purpose
            </p>
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-4 col-xl-3">
          <div className="feature">
            <span className="feature__icon">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M12,3A10,10,0,0,0,2,13v7a1,1,0,0,0,1,1H6a3,3,0,0,0,3-3V16a3,3,0,0,0-3-3H4a8,8,0,0,1,16,0H18a3,3,0,0,0-3,3v2a3,3,0,0,0,3,3h3a1,1,0,0,0,1-1V13A10,10,0,0,0,12,3ZM6,15a1,1,0,0,1,1,1v2a1,1,0,0,1-1,1H4V15Zm14,4H18a1,1,0,0,1-1-1V16a1,1,0,0,1,1-1h2Z" />
              </svg>
            </span>
            <h3 className="feature__title">Valid shares only</h3>
            <p className="feature__text">
              Various versions have evolved over the years, sometimes by
              accident, sometimes on purpose
            </p>
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-4 col-xl-3">
          <div className="feature feature--last">
            <span className="feature__icon">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M22,9.67A1,1,0,0,0,21.14,9l-5.69-.83L12.9,3a1,1,0,0,0-1.8,0L8.55,8.16,2.86,9a1,1,0,0,0-.81.68,1,1,0,0,0,.25,1l4.13,4-1,5.68a1,1,0,0,0,.4,1,1,1,0,0,0,1.05.07L12,18.76l5.1,2.68a.93.93,0,0,0,.46.12,1,1,0,0,0,.59-.19,1,1,0,0,0,.4-1l-1-5.68,4.13-4A1,1,0,0,0,22,9.67Zm-6.15,4a1,1,0,0,0-.29.89l.72,4.19-3.76-2a1,1,0,0,0-.94,0l-3.76,2,.72-4.19a1,1,0,0,0-.29-.89l-3-3,4.21-.61a1,1,0,0,0,.76-.55L12,5.7l1.88,3.82a1,1,0,0,0,.76.55l4.21.61Z" />
              </svg>
            </span>
            <h3 className="feature__title">Choose your plan</h3>
            <p className="feature__text">
              If you are going to use a passage of Lorem Ipsum, you need to be
              sure there isn't anything embarrassing hidden in the middle of
              text
            </p>
          </div>
        </div>
      </div>
      <div className="row row--grid">
        {/* title */}
        <div className="col-12">
          <div className="main__title">
            <h2>Select Your Plan</h2>
            <p>
              No hidden fees, equipment rentals, or installation appointments.
            </p>
          </div>
        </div>
        {/* end title */}
        <div className="col-12 col-md-6 col-lg-4 order-md-2 order-lg-1">
          <div className="plan plan--green">
            <h3 className="plan__title">Free</h3>
            <span className="plan__price">
              $0<span> /month</span>
            </span>
            <ul className="plan__list">
              <li className="green">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z" />
                </svg>{" "}
                Volna Originals
              </li>
              <li className="green">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z" />
                </svg>{" "}
                Switch plans or cancel anytime
              </li>
              <li className="red">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z" />
                </svg>{" "}
                Stream 65+ top Live
              </li>
              <li className="red">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z" />
                </svg>{" "}
                Music channels
              </li>
            </ul>
            <button className="plan__btn" type="button">
              Select plan
            </button>
          </div>
        </div>
        <div className="col-12 col-lg-4 order-md-1 order-lg-2">
          <div className="plan plan--red">
            <h3 className="plan__title">Starter</h3>
            <span className="plan__price">
              $14.99<span> /month</span>
            </span>
            <ul className="plan__list">
              <li className="green">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z" />
                </svg>{" "}
                Volna Originals
              </li>
              <li className="green">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z" />
                </svg>{" "}
                Switch plans or cancel anytime
              </li>
              <li className="green">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z" />
                </svg>{" "}
                Stream 65+ top Live
              </li>
              <li className="red">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z" />
                </svg>{" "}
                Music channels
              </li>
            </ul>
            <button className="plan__btn" type="button">
              Select plan
            </button>
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-4 order-md-3 order-lg-3">
          <div className="plan plan--purple">
            <h3 className="plan__title">Premium</h3>
            <span className="plan__price">
              $39.99<span> /month</span>
            </span>
            <ul className="plan__list">
              <li className="green">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z" />
                </svg>{" "}
                Volna Originals
              </li>
              <li className="green">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z" />
                </svg>{" "}
                Switch plans or cancel anytime
              </li>
              <li className="green">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z" />
                </svg>{" "}
                Stream 65+ top Live
              </li>
              <li className="green">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z" />
                </svg>{" "}
                Music channels
              </li>
            </ul>
            <button className="plan__btn" type="button">
              Select plan
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
