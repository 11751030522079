import "./App.css";
import { BrowserRouter } from "react-router-dom";
import Homepage from "./Frontend/Homepage";
import BounceLoader from "react-spinners/BounceLoader";
import { useState, useEffect } from "react";
import { data } from "./assets/js/function";

function Routing() {
  let [change, setChange] = useState(false);
  useEffect(() => {
    let timeout = setTimeout(() => {
      let dataM = data.apiData.whereCatagory(5);
      if (dataM) {
        setChange(true);
      } else {
        let interval = setInterval(() => {
          if (dataM) {
            setChange(true);
            clearInterval(interval);
          }
        }, 3000);
      }
      clearTimeout(timeout);
    }, 3000);
  }, []);

  useEffect(() => {
    setChange(change);
  }, [change]);
  return (
    <BrowserRouter>
      {change ? (
        <Homepage />
      ) : (
        <div className="w-100 p-5 loader_css">
          <center>
            <BounceLoader color="#ffffff" loading="true" size="50" />
          </center>
        </div>
      )}
    </BrowserRouter>
  );
}

export default Routing;
