import React from "react";
import { Link } from "react-router-dom";
import Events from "./../../Frontend/Events/index";

/**
 * @author
 * @function EventsPage
 **/

const EventsPage = (props) => {
  return (
    <div>
      <div className="row row--grid">
        {/* breadcrumb */}
        <div className="col-12">
          <ul className="breadcrumb" style={{ background: "transparent" }}>
            <li className="breadcrumb__item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb__item breadcrumb__item--active">
              Events
            </li>
          </ul>
        </div>
        {/* end breadcrumb */}
        {/* title */}
        <div className="col-12">
          <div className="main__title main__title--page">
            <h1>Events</h1>
          </div>
        </div>
        {/* end title */}
      </div>
      <div className="row row--grid">
        <div className="col-12">
          {/* <div className="main__filter">
            <form action="#" className="main__filter-search">
              <input type="text" placeholder="Date, place, etc." />
              <button type="button">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M21.71,20.29,18,16.61A9,9,0,1,0,16.61,18l3.68,3.68a1,1,0,0,0,1.42,0A1,1,0,0,0,21.71,20.29ZM11,18a7,7,0,1,1,7-7A7,7,0,0,1,11,18Z" />
                </svg>
              </button>
            </form>
            <div className="main__filter-wrap">
              <select className="main__select" name="genres">
                <option value="upcoming">Upcoming events</option>
                <option value="past">Past events</option>
                <option value="free">Free events</option>
              </select>
            </div>
            <div className="slider-radio">
              <input
                type="radio"
                name="grade"
                id="upcoming"
                defaultChecked="checked"
              />
              <label htmlFor="upcoming">Upcoming</label>
              <input type="radio" name="grade" id="past" />
              <label htmlFor="past">Past</label>
              <input type="radio" name="grade" id="free" />
              <label htmlFor="free">Free</label>
            </div>
          </div> */}
          <Events title="no" />
          <div className="row row--grid">
            <div className="col-12">
              <button className="main__load" type="button">
                Load more
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventsPage;
