import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { data } from "./../../assets/js/function";
import BounceLoader from "react-spinners/BounceLoader";
/**
 * @author
 * @function Events
 **/

const Events = (props) => {
  let [events, setevents] = useState();
  useEffect(() => {
    let timeout = setTimeout(() => {
      let dataM = data.apiData.website_events();
      if (dataM) {
        setevents(dataM);
      } else {
        let interval = setInterval(() => {
          if (dataM) {
            setevents(dataM);
            clearInterval(interval);
          }
        }, 3000);
      }
      clearTimeout(timeout);
    }, 3000);
  }, []);

  useEffect(() => {
    setevents(events);
  }, [events]);
  return (
    <section className="row row--grid">
      {/* title */}
      {events ? (
        <React.Fragment>
          <div className="col-12">
            {props.title == "no" ? (
              ""
            ) : (
              <div className="main__title">
                <h2>Register</h2>
                <Link to="events" className="main__link">
                  See all{" "}
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M17.92,11.62a1,1,0,0,0-.21-.33l-5-5a1,1,0,0,0-1.42,1.42L14.59,11H7a1,1,0,0,0,0,2h7.59l-3.3,3.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l5-5a1,1,0,0,0,.21-.33A1,1,0,0,0,17.92,11.62Z" />
                  </svg>
                </Link>
              </div>
            )}
          </div>
          {/* end title */}
          <div className="col-12">
            <div className="main__carousel-wrap">
              <div
                className="main__carousel main__carousel--events owl-carousel"
                id="events">
                {events.map((v, k) => {
                  return (
                    <div className="event" data-bg={v.image} key={k}>
                      {v.has_event == "true" ? (
                        v.sold_out != "true" ? (
                          <Link
                            to={{ pathname: v.event_link }}
                            target="_blank"
                            className="event__ticket">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24">
                              <path d="M9,10a1,1,0,0,0-1,1v2a1,1,0,0,0,2,0V11A1,1,0,0,0,9,10Zm12,1a1,1,0,0,0,1-1V6a1,1,0,0,0-1-1H3A1,1,0,0,0,2,6v4a1,1,0,0,0,1,1,1,1,0,0,1,0,2,1,1,0,0,0-1,1v4a1,1,0,0,0,1,1H21a1,1,0,0,0,1-1V14a1,1,0,0,0-1-1,1,1,0,0,1,0-2ZM20,9.18a3,3,0,0,0,0,5.64V17H10a1,1,0,0,0-2,0H4V14.82A3,3,0,0,0,4,9.18V7H8a1,1,0,0,0,2,0H20Z" />
                            </svg>{" "}
                            Buy ticket
                          </Link>
                        ) : (
                          <span className="event__out">Sold out</span>
                        )
                      ) : (
                        ""
                      )}
                      <span className="event__date">{v.event_date}</span>
                      {/* <span className="event__time">8:00 pm</span> */}
                      <h3 className="event__title">
                        <Link to="event">{v.title}</Link>
                      </h3>
                      {/* <p className="event__address">
                        1 East Plumb Branch St.Saint Petersburg, FL 33702
                      </p> */}
                    </div>
                  );
                })}
              </div>
              <button
                className="main__nav main__nav--prev"
                data-nav="#events"
                type="button">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M17,11H9.41l3.3-3.29a1,1,0,1,0-1.42-1.42l-5,5a1,1,0,0,0-.21.33,1,1,0,0,0,0,.76,1,1,0,0,0,.21.33l5,5a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L9.41,13H17a1,1,0,0,0,0-2Z" />
                </svg>
              </button>
              <button
                className="main__nav main__nav--next"
                data-nav="#events"
                type="button">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M17.92,11.62a1,1,0,0,0-.21-.33l-5-5a1,1,0,0,0-1.42,1.42L14.59,11H7a1,1,0,0,0,0,2h7.59l-3.3,3.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l5-5a1,1,0,0,0,.21-.33A1,1,0,0,0,17.92,11.62Z" />
                </svg>
              </button>
            </div>
          </div>
        </React.Fragment>
      ) : (
        <center style={{ width: "100%" }}>
          <BounceLoader color="#ffffff" loading="true" size="50" />
        </center>
      )}
    </section>
  );
};

export default Events;
