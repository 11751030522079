import React from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import { Cloudinary } from "cloudinary-core";
/**
 * @author
 * @function AdminPage
 **/

const AdminPage = (props) => {
  let handleSubmit = (event) => {
    event.preventDefault();
    let title = $("form input[name='name']").val();
    let email = $("form input[name='email']").val();
    let category = $("form select[name='category'] option:selected").val();
    var file_data = $("input[name='audio']").prop("files")[0];
    var file_image = $("input[name='image']").prop("files")[0];
    console.log(file_image);
    var form_data = new FormData();
    form_data.append("file_audio", file_data);
    form_data.append("file_image", file_image);
    form_data.append("title", title);
    form_data.append("email", email);
    form_data.append("category", category);
    form_data.append("add_data", true);
    $.ajax({
      url: "http://localhost/kairos/api/add_data.php", // point to server-side controller method
      cache: false,
      contentType: false,
      processData: false,
      data: form_data,
      type: "post",
      beforeSend: function (res) {
        console.log("Sending file");
      },
      success: function (response) {
        console.log(response);
      },
      error: function (response) {
        console.warn(response);
      }
    });
  };
  return (
    <div>
      <div className="row row--grid">
        {/* breadcrumb */}
        <div className="col-12">
          <ul className="breadcrumb" style={{ background: "transparent" }}>
            <li className="breadcrumb__item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb__item breadcrumb__item--active">Admin</li>
          </ul>
        </div>
        {/* end breadcrumb */}
        {/* title */}
        <div className="col-12">
          <div
            className="main__title main__title--page"
            style={{ marginTop: 20 }}>
            <h2>Welcome Admin</h2>
          </div>
        </div>
        {/* end title */}
      </div>
      <div className="row row--grid">
        <div className="col-12 col-lg-4 col-xl-4">
          <form
            action="#"
            className="sign__form sign__form--contacts"
            onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-12">
                <div className="sign__group">
                  <select name="category" className="main__select w-100" id="">
                    <option value="This Week">Select Categoty</option>
                    <option value="This Week">This Week</option>
                  </select>
                </div>
              </div>
              <div className="col-12">
                <div className="sign__group">
                  <input
                    type="text"
                    name="name"
                    className="sign__input"
                    placeholder="Title"
                  />
                </div>
              </div>

              <div className="col-12">
                <div className="form-group">
                  <label className="text-white" htmlFor="filedata">
                    Select Audio
                  </label>
                  <div className="sign__group">
                    <input
                      id="file"
                      type="file"
                      style={{ paddingTop: 8 }}
                      name="audio"
                      className="sign__input"
                      placeholder="Email"
                    />
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <label className="text-white" htmlFor="filedata">
                    Select Image
                  </label>
                  <div className="sign__group">
                    <input
                      type="file"
                      style={{ paddingTop: 8 }}
                      name="image"
                      className="sign__input"
                      placeholder="Email"
                    />
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="sign__group">
                  <textarea
                    name="text"
                    className="sign__textarea"
                    placeholder="Audio Description"
                    defaultValue={""}
                  />
                </div>
              </div>
              <div className="col-12 col-xl-3">
                <button type="submit" className="sign__btn">
                  Upload
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="col-12 col-md-8 col-lg-8 col-xl-8">
          <div className="dashbox">
            <div className="dashbox__title">
              <h3>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M21.65,2.24a1,1,0,0,0-.8-.23l-13,2A1,1,0,0,0,7,5V15.35A3.45,3.45,0,0,0,5.5,15,3.5,3.5,0,1,0,9,18.5V10.86L20,9.17v4.18A3.45,3.45,0,0,0,18.5,13,3.5,3.5,0,1,0,22,16.5V3A1,1,0,0,0,21.65,2.24ZM5.5,20A1.5,1.5,0,1,1,7,18.5,1.5,1.5,0,0,1,5.5,20Zm13-2A1.5,1.5,0,1,1,20,16.5,1.5,1.5,0,0,1,18.5,18ZM20,7.14,9,8.83v-3L20,4.17Z" />
                </svg>{" "}
                Previously uploaded
              </h3>
              <div className="dashbox__wrap">
                <Link
                  className="dashbox__more"
                  to="#"
                  style={{ width: "100%", marginRight: 15 }}>
                  View All
                </Link>
                <Link
                  className="dashbox__more sign__btn"
                  to=""
                  style={{ height: 29, padding: 10 }}>
                  Upload
                </Link>
              </div>
            </div>
            <div className="dashbox__list-wrap">
              <ul className="main__list main__list--dashbox">
                <li className="single-item">
                  <a
                    data-link="true"
                    data-title="Got What I Got"
                    data-artist="Jason Aldean"
                    data-img="img/covers/cover.svg"
                    href
                    className="single-item__cover">
                    <img src="img/covers/cover.svg" alt="" />
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="M18.54,9,8.88,3.46a3.42,3.42,0,0,0-5.13,3V17.58A3.42,3.42,0,0,0,7.17,21a3.43,3.43,0,0,0,1.71-.46L18.54,15a3.42,3.42,0,0,0,0-5.92Zm-1,4.19L7.88,18.81a1.44,1.44,0,0,1-1.42,0,1.42,1.42,0,0,1-.71-1.23V6.42a1.42,1.42,0,0,1,.71-1.23A1.51,1.51,0,0,1,7.17,5a1.54,1.54,0,0,1,.71.19l9.66,5.58a1.42,1.42,0,0,1,0,2.46Z" />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="M16,2a3,3,0,0,0-3,3V19a3,3,0,0,0,6,0V5A3,3,0,0,0,16,2Zm1,17a1,1,0,0,1-2,0V5a1,1,0,0,1,2,0ZM8,2A3,3,0,0,0,5,5V19a3,3,0,0,0,6,0V5A3,3,0,0,0,8,2ZM9,19a1,1,0,0,1-2,0V5A1,1,0,0,1,9,5Z" />
                    </svg>
                  </a>
                  <div className="single-item__title">
                    <h4>
                      <a href="/profile">Got What I Got</a>
                    </h4>
                    <span>
                      <a href="/artist.html">Jason Aldean</a>
                    </span>
                  </div>
                  <a className="single-item__add" href="/profile">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="M19,11H13V5a1,1,0,0,0-2,0v6H5a1,1,0,0,0,0,2h6v6a1,1,0,0,0,2,0V13h6a1,1,0,0,0,0-2Z" />
                    </svg>
                  </a>
                  <a className="single-item__export" href="/profile">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="M21,14a1,1,0,0,0-1,1v4a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V15a1,1,0,0,0-2,0v4a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V15A1,1,0,0,0,21,14Zm-9.71,1.71a1,1,0,0,0,.33.21.94.94,0,0,0,.76,0,1,1,0,0,0,.33-.21l4-4a1,1,0,0,0-1.42-1.42L13,12.59V3a1,1,0,0,0-2,0v9.59l-2.29-2.3a1,1,0,1,0-1.42,1.42Z" />
                    </svg>
                  </a>
                  <span className="single-item__time">2:58</span>
                </li>
                <li className="single-item">
                  <a
                    data-link="true"
                    data-title="Supalonely"
                    data-artist="BENEE Featuring"
                    data-img="img/covers/cover7.jpg"
                    href
                    className="single-item__cover">
                    <img src="img/covers/cover7.jpg" alt="" />
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="M18.54,9,8.88,3.46a3.42,3.42,0,0,0-5.13,3V17.58A3.42,3.42,0,0,0,7.17,21a3.43,3.43,0,0,0,1.71-.46L18.54,15a3.42,3.42,0,0,0,0-5.92Zm-1,4.19L7.88,18.81a1.44,1.44,0,0,1-1.42,0,1.42,1.42,0,0,1-.71-1.23V6.42a1.42,1.42,0,0,1,.71-1.23A1.51,1.51,0,0,1,7.17,5a1.54,1.54,0,0,1,.71.19l9.66,5.58a1.42,1.42,0,0,1,0,2.46Z" />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="M16,2a3,3,0,0,0-3,3V19a3,3,0,0,0,6,0V5A3,3,0,0,0,16,2Zm1,17a1,1,0,0,1-2,0V5a1,1,0,0,1,2,0ZM8,2A3,3,0,0,0,5,5V19a3,3,0,0,0,6,0V5A3,3,0,0,0,8,2ZM9,19a1,1,0,0,1-2,0V5A1,1,0,0,1,9,5Z" />
                    </svg>
                  </a>
                  <div className="single-item__title">
                    <h4>
                      <a href="/profile">Supalonely</a>
                    </h4>
                    <span>
                      <a href="/artist.html">BENEE Featuring</a>
                    </span>
                  </div>
                  <a className="single-item__add" href="/profile">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="M19,11H13V5a1,1,0,0,0-2,0v6H5a1,1,0,0,0,0,2h6v6a1,1,0,0,0,2,0V13h6a1,1,0,0,0,0-2Z" />
                    </svg>
                  </a>
                  <a className="single-item__export" href="/profile">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="M21,14a1,1,0,0,0-1,1v4a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V15a1,1,0,0,0-2,0v4a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V15A1,1,0,0,0,21,14Zm-9.71,1.71a1,1,0,0,0,.33.21.94.94,0,0,0,.76,0,1,1,0,0,0,.33-.21l4-4a1,1,0,0,0-1.42-1.42L13,12.59V3a1,1,0,0,0-2,0v9.59l-2.29-2.3a1,1,0,1,0-1.42,1.42Z" />
                    </svg>
                  </a>
                  <span className="single-item__time">3:14</span>
                </li>
                <li className="single-item">
                  <a
                    data-link="true"
                    data-title="Girls In The Hood"
                    data-artist="Megan Thee"
                    data-img="img/covers/cover8.jpg"
                    href
                    className="single-item__cover">
                    <img src="img/covers/cover8.jpg" alt="" />
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="M18.54,9,8.88,3.46a3.42,3.42,0,0,0-5.13,3V17.58A3.42,3.42,0,0,0,7.17,21a3.43,3.43,0,0,0,1.71-.46L18.54,15a3.42,3.42,0,0,0,0-5.92Zm-1,4.19L7.88,18.81a1.44,1.44,0,0,1-1.42,0,1.42,1.42,0,0,1-.71-1.23V6.42a1.42,1.42,0,0,1,.71-1.23A1.51,1.51,0,0,1,7.17,5a1.54,1.54,0,0,1,.71.19l9.66,5.58a1.42,1.42,0,0,1,0,2.46Z" />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="M16,2a3,3,0,0,0-3,3V19a3,3,0,0,0,6,0V5A3,3,0,0,0,16,2Zm1,17a1,1,0,0,1-2,0V5a1,1,0,0,1,2,0ZM8,2A3,3,0,0,0,5,5V19a3,3,0,0,0,6,0V5A3,3,0,0,0,8,2ZM9,19a1,1,0,0,1-2,0V5A1,1,0,0,1,9,5Z" />
                    </svg>
                  </a>
                  <div className="single-item__title">
                    <h4>
                      <a href="/profile">Girls In The Hood</a>
                    </h4>
                    <span>
                      <a href="/artist.html">Megan Thee</a>
                    </span>
                  </div>
                  <a className="single-item__add" href="/profile">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="M19,11H13V5a1,1,0,0,0-2,0v6H5a1,1,0,0,0,0,2h6v6a1,1,0,0,0,2,0V13h6a1,1,0,0,0,0-2Z" />
                    </svg>
                  </a>
                  <a className="single-item__export" href="/profile">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="M21,14a1,1,0,0,0-1,1v4a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V15a1,1,0,0,0-2,0v4a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V15A1,1,0,0,0,21,14Zm-9.71,1.71a1,1,0,0,0,.33.21.94.94,0,0,0,.76,0,1,1,0,0,0,.33-.21l4-4a1,1,0,0,0-1.42-1.42L13,12.59V3a1,1,0,0,0-2,0v9.59l-2.29-2.3a1,1,0,1,0-1.42,1.42Z" />
                    </svg>
                  </a>
                  <span className="single-item__time">3:21</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminPage;
