import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { data } from "./../../assets/js/function";
import BounceLoader from "react-spinners/BounceLoader";
/**
 * @author
 * @function PodcastStandard
 **/

const PodcastStandard = (props) => {
  let [shows, setshows] = useState();
  useEffect(() => {
    let timeout = setTimeout(() => {
      let dataM = data.apiData.website_shows();
      if (dataM) {
        setshows(dataM);
      } else {
        let interval = setInterval(() => {
          if (dataM) {
            setshows(dataM);
            clearInterval(interval);
          }
        }, 3000);
      }
      clearTimeout(timeout);
    }, 3000);
  }, [shows]);
  return (
    <section className="row row--grid">
      {/* title */}
      {shows ? (
        <React.Fragment>
          <div className="col-12">
            {props.title == "no" ? (
              ""
            ) : (
              <div className="main__title">
                <h2>Shows</h2>
                <Link to="/shows" className="main__link">
                  See all{" "}
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M17.92,11.62a1,1,0,0,0-.21-.33l-5-5a1,1,0,0,0-1.42,1.42L14.59,11H7a1,1,0,0,0,0,2h7.59l-3.3,3.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l5-5a1,1,0,0,0,.21-.33A1,1,0,0,0,17.92,11.62Z" />
                  </svg>
                </Link>
              </div>
            )}
          </div>
          {/* end title */}
          <div className="col-12">
            <div className="main__carousel-wrap">
              <div
                className="main__carousel main__carousel--podcasts owl-carousel"
                id="podcasts">
                {shows.map((v, k) => {
                  return (
                    <div className="live" key={k}>
                      <Link
                        to={
                          "/show/" +
                          v.id +
                          "/" +
                          v.name
                            .replace(/ /g, "-")
                            .replace(/,/g, "")
                            .toLowerCase()
                        }
                        className="live__cover">
                        <img src={v.image} alt="" />
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24">
                          <path d="M18.54,9,8.88,3.46a3.42,3.42,0,0,0-5.13,3V17.58A3.42,3.42,0,0,0,7.17,21a3.43,3.43,0,0,0,1.71-.46L18.54,15a3.42,3.42,0,0,0,0-5.92Zm-1,4.19L7.88,18.81a1.44,1.44,0,0,1-1.42,0,1.42,1.42,0,0,1-.71-1.23V6.42a1.42,1.42,0,0,1,.71-1.23A1.51,1.51,0,0,1,7.17,5a1.54,1.54,0,0,1,.71.19l9.66,5.58a1.42,1.42,0,0,1,0,2.46Z" />
                        </svg>
                      </Link>
                      <h3 className="live__title">
                        <Link
                          href="http://www.youtube.com/watch?v=0O2aH4XLbto"
                          className="open-video">
                          {v.name}
                        </Link>
                      </h3>
                    </div>
                  );
                })}
              </div>
              <button
                className="main__nav main__nav--prev"
                data-nav="#podcasts"
                type="button">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M17,11H9.41l3.3-3.29a1,1,0,1,0-1.42-1.42l-5,5a1,1,0,0,0-.21.33,1,1,0,0,0,0,.76,1,1,0,0,0,.21.33l5,5a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L9.41,13H17a1,1,0,0,0,0-2Z" />
                </svg>
              </button>
              <button
                className="main__nav main__nav--next"
                data-nav="#podcasts"
                type="button">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M17.92,11.62a1,1,0,0,0-.21-.33l-5-5a1,1,0,0,0-1.42,1.42L14.59,11H7a1,1,0,0,0,0,2h7.59l-3.3,3.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l5-5a1,1,0,0,0,.21-.33A1,1,0,0,0,17.92,11.62Z" />
                </svg>
              </button>
            </div>
          </div>
        </React.Fragment>
      ) : (
        <center style={{ width: "100%" }}>
          <BounceLoader color="#ffffff" loading="true" size="50" />
        </center>
      )}
    </section>
  );
};

export default PodcastStandard;
