import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { data } from "./../../assets/js/function";
import BounceLoader from "react-spinners/BounceLoader";
import { Markup } from "interweave";

/**
 * @author
 * @function Slider
 **/

const Slider = (props) => {
  let [sliders, setsliders] = useState();
  useEffect(() => {
    let timeout = setTimeout(() => {
      let dataM = data.apiData.sliders();
      if (dataM) {
        setsliders(dataM);
      } else {
        let interval = setInterval(() => {
          if (dataM) {
            setsliders(dataM);
            clearInterval(interval);
          }
        }, 3000);
      }
      clearTimeout(timeout);
    }, 3000);
  }, []);

  useEffect(() => {
    setsliders(sliders);
  }, [sliders]);
  return (
    <section className="row">
      {sliders ? (
        <div className="col-12">
          <div className="hero owl-carousel" id="hero">
            {sliders.map((v, k) => {
              return (
                <div className="hero__slide" data-bg={v.image} key={k}>
                  <h1 className="hero__title">{v.title}</h1>
                  <div className="hero__text">
                    <Markup content={v.content} />
                  </div>
                </div>
              );
            })}
          </div>
          <button
            className="main__nav main__nav--hero main__nav--prev"
            data-nav="#hero"
            type="button">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M17,11H9.41l3.3-3.29a1,1,0,1,0-1.42-1.42l-5,5a1,1,0,0,0-.21.33,1,1,0,0,0,0,.76,1,1,0,0,0,.21.33l5,5a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L9.41,13H17a1,1,0,0,0,0-2Z" />
            </svg>
          </button>
          <button
            className="main__nav main__nav--hero main__nav--next"
            data-nav="#hero"
            type="button">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M17.92,11.62a1,1,0,0,0-.21-.33l-5-5a1,1,0,0,0-1.42,1.42L14.59,11H7a1,1,0,0,0,0,2h7.59l-3.3,3.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l5-5a1,1,0,0,0,.21-.33A1,1,0,0,0,17.92,11.62Z" />
            </svg>
          </button>
        </div>
      ) : (
        <center style={{ width: "100%" }}>
          <BounceLoader color="#ffffff" loading="true" size="20" />
        </center>
      )}
    </section>
  );
};

export default Slider;
