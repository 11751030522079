import React, { useEffect, useState } from "react";
import BounceLoader from "react-spinners/BounceLoader";
import $ from "jquery";
/**
 * @author
 * @function HomeLoader
 **/

const HomeLoader = (props) => {
  let l = props.location.pathname;
  let [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    $(function () {
      //offline
      setInterval(() => {
        // var src = $("script[src='/js/main.js']").attr("src");
        // $("script[src='/js/main.js']").remove();
        //online
        var src = $("script[src='./js/main.js']").attr("src");
        $("script[src='./js/main.js']").remove();
        //More
        var s = document.createElement("script");
        s.src = src;
        $("body").append(s);
      }, 1000);
      let timeout = setTimeout(() => {
        setLoading(false);
        clearTimeout(timeout);
      }, 3000);
    });
  }, []);

  useEffect(() => {
    //offline
    // setInterval(() => {
    // var src = $("script[src='/js/main.js']").attr("src");
    // $("script[src='/js/main.js']").remove();
    //online
    var src = $("script[src='./js/main.js']").attr("src");
    $("script[src='./js/main.js']").remove();
    //More
    var s = document.createElement("script");
    s.src = src;
    $("body").append(s);
    // }, 1000);
    let timeout = setTimeout(() => {
      setLoading(false);
      clearTimeout(timeout);
    }, 3000);
  }, [l]);

  return (
    <React.Fragment>
      <div
        className="container p-5"
        style={{ display: loading ? "block" : "none" }}>
        <div className="w-100 p-5">
          <center>
            <BounceLoader color="#ffffff" loading="true" size="50" />
          </center>
        </div>
      </div>
      <div style={{ display: !loading ? "block" : "none" }}>
        {props.children}
      </div>
    </React.Fragment>
  );
};

export default HomeLoader;
