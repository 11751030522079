import $ from "jquery";
// var apiUrl = "http://127.0.0.1:8000/api";
// var apiUrl = "https://dashboard.caliradio.ng/api";
var apiUrl = "https://dashboard.kairoscentral.com/api";

var datawherecat = (id) => {
  $.get(apiUrl + "/audio-category/" + id, function (data, textStatus, jqXHR) {
    if (data != null && data.length != 0) {
      localStorage.setItem("whereCatagory" + id, JSON.stringify(data)); //stringify object and store
      var d = JSON.parse(localStorage.getItem("whereCatagory" + id)); //retrieve the objec
      return d;
    }
  });
};

var getSettings = () => {
  $.get(apiUrl + "/settings", function (data, textStatus, jqXHR) {
    if (data != null && data.length != 0) {
      localStorage.setItem("website_settings", JSON.stringify(data)); //stringify object and store
      var d = JSON.parse(localStorage.getItem("website_settings")); //retrieve the objec
      return d;
    }
  });
};

var getSliders = () => {
  $.get(apiUrl + "/sliders", function (data, textStatus, jqXHR) {
    if (data != null && data.length != 0) {
      localStorage.setItem("website_sliders", JSON.stringify(data)); //stringify object and store
      var d = JSON.parse(localStorage.getItem("website_sliders")); //retrieve the objec
      return d;
    }
  });
};

var datawhereId = (id) => {
  $.get(apiUrl + "/audio/" + id, function (data, textStatus, jqXHR) {
    if (data != null && data.length != 0) {
      localStorage.setItem("where_landing_Id" + id, JSON.stringify(data)); //stringify object and store
      var d = JSON.parse(localStorage.getItem("where_landing_Id" + id)); //retrieve the objec
      return d;
    }
  });
};

var getShowdata = (id) => {
  $.get(apiUrl + "/show-listings/" + id, function (data, textStatus, jqXHR) {
    if (data != null && data.length != 0) {
      localStorage.setItem("website_show_data" + id, JSON.stringify(data)); //stringify object and store
      var d = JSON.parse(localStorage.getItem("website_show_data" + id)); //retrieve the objec
      return d;
    }
  });
};

var getEvents = () => {
  $.get(apiUrl + "/events", function (data, textStatus, jqXHR) {
    if (data != null && data.length != 0) {
      localStorage.setItem("website_events", JSON.stringify(data)); //stringify object and store
      var d = JSON.parse(localStorage.getItem("website_events")); //retrieve the objec
      return d;
    }
  });
};

var getShows = () => {
  $.get(apiUrl + "/shows", function (data, textStatus, jqXHR) {
    if (data != null && data.length != 0) {
      localStorage.setItem("website_shows", JSON.stringify(data)); //stringify object and store
      var d = JSON.parse(localStorage.getItem("website_shows")); //retrieve the objec
      return d;
    }
  });
};

var getArtists = () => {
  $.get(apiUrl + "/artists", function (data, textStatus, jqXHR) {
    if (data != null && data.length != 0) {
      localStorage.setItem("website_artists", JSON.stringify(data)); //stringify object and store
      var d = JSON.parse(localStorage.getItem("website_artists")); //retrieve the objec
      return d;
    }
  });
};

var getArtist = (id) => {
  $.get(apiUrl + "/artist/" + id, function (data, textStatus, jqXHR) {
    if (data != null && data.length != 0) {
      localStorage.setItem("website_artist_id" + id, JSON.stringify(data)); //stringify object and store
      var d = JSON.parse(localStorage.getItem("website_artist_id" + id)); //retrieve the objec
      return d;
    }
  });
};

setInterval(() => {
  datawherecat(5);
  getSettings();
  getSliders();
  getEvents();
  getShows();
  getArtists();
}, 120000);

let d = setTimeout(() => {
  datawherecat(5);
  getSettings();
  getSliders();
  getEvents();
  getShows();
  getArtists();

  clearTimeout(d);
}, 100);

export const data = {
  apiUrl: apiUrl,
  apiData: {
    whereCatagory: (id) => {
      var d = JSON.parse(localStorage.getItem("whereCatagory" + id));
      if (d) {
        datawherecat(id);
        return d;
      } else {
        datawherecat(id);
        return null;
      }
    },
    whereId: (id) => {
      var d = JSON.parse(localStorage.getItem("where_landing_Id" + id));
      if (d) {
        datawhereId(id);
        return d;
      } else {
        datawhereId(id);
        return null;
      }
    },
    settings: () => {
      var d = JSON.parse(localStorage.getItem("website_settings"));
      if (d) {
        getSettings();
        return d;
      } else {
        getSettings();
        return null;
      }
    },
    sliders: () => {
      var d = JSON.parse(localStorage.getItem("website_sliders"));
      if (d) {
        getSliders();
        return d;
      } else {
        getSliders();
        return null;
      }
    },
    website_events: () => {
      var d = JSON.parse(localStorage.getItem("website_events"));
      if (d) {
        getSliders();
        return d;
      } else {
        getSliders();
        return null;
      }
    },
    website_shows: () => {
      var d = JSON.parse(localStorage.getItem("website_shows"));
      if (d) {
        getSliders();
        return d;
      } else {
        getSliders();
        return null;
      }
    },
    website_artists: () => {
      var d = JSON.parse(localStorage.getItem("website_artists"));
      if (d) {
        getArtists();
        return d;
      } else {
        getArtists();
        return null;
      }
    },
    whereArtistId: (id) => {
      var d = JSON.parse(localStorage.getItem("website_artist_id" + id));
      if (d) {
        getArtist(id);
        return d;
      } else {
        getArtist(id);
        return null;
      }
    },
    website_show_data: (id) => {
      var d = JSON.parse(localStorage.getItem("website_show_data" + id));
      if (d) {
        getShowdata(id);
        return d;
      } else {
        getShowdata(id);
        return null;
      }
    }
  }
};
