import React from 'react';

/**
 * @author
 * @function Modal
 **/

const Modal = (props) => {
  return (
    <React.Fragment>
      {/* modal ticket */}
      <form
        action='#'
        id='modal-ticket'
        className='zoom-anim-dialog mfp-hide modal modal--form'>
        <button className='modal__close' type='button'>
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
            <path d='M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z' />
          </svg>
        </button>
        <h4 className='sign__title'>To buy tickets</h4>
        <div className='sign__group sign__group--row'>
          <label className='sign__label'>Your balance:</label>
          <span className='sign__value'>$90.99</span>
        </div>
        <div className='sign__group sign__group--row'>
          <label className='sign__label' htmlFor='value'>
            Choose ticket:
          </label>
          <select className='sign__select' name='value' id='value'>
            <option value={50}>Regular - $49</option>
            <option value={100}>VIP Light - $99</option>
            <option value={200}>VIP - $169</option>
          </select>
          <span className='sign__text sign__text--small'>
            You can spend money from your account on the renewal of the
            connected packages, or on the purchase of goods on our website.
          </span>
        </div>
        <button className='sign__btn' type='button'>
          Buy
        </button>
      </form>
      {/* end modal ticket */}
      {/* modal info */}
      <div
        id='modal-info2'
        className='zoom-anim-dialog mfp-hide modal modal--info'>
        <span className='modal__icon modal__icon--green'>
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
            <path d='M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z' />
          </svg>
        </span>
        <button className='modal__close' type='button'>
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
            <path d='M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z' />
          </svg>
        </button>
        <h4 className='sign__title'>Payment #51 was successful!</h4>
        <div className='sign__group sign__group--row'>
          <label className='sign__label'>Total cost:</label>
          <span className='sign__value'>$18.00</span>
        </div>
        <div className='sign__group sign__group--row'>
          <label className='sign__label'>
            Payment method: <b>Paypal</b>
          </label>
          <span className='sign__text sign__text--small'>
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour, or randomised words which don't look even slightly
            believable.
          </span>
        </div>
      </div>
      {/* end modal info */}
      {/* modal info */}
      <div
        id='modal-info3'
        className='zoom-anim-dialog mfp-hide modal modal--info'>
        <span className='modal__icon modal__icon--red'>
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
            <path d='M15.71,8.29a1,1,0,0,0-1.42,0L12,10.59,9.71,8.29A1,1,0,0,0,8.29,9.71L10.59,12l-2.3,2.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l2.29,2.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L13.41,12l2.3-2.29A1,1,0,0,0,15.71,8.29Zm3.36-3.36A10,10,0,1,0,4.93,19.07,10,10,0,1,0,19.07,4.93ZM17.66,17.66A8,8,0,1,1,20,12,7.95,7.95,0,0,1,17.66,17.66Z' />
          </svg>
        </span>
        <button className='modal__close' type='button'>
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
            <path d='M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z' />
          </svg>
        </button>
        <h4 className='sign__title'>Payment #50 failed!</h4>
        <div className='sign__group sign__group--row'>
          <label className='sign__label'>Total cost:</label>
          <span className='sign__value'>$18.00</span>
        </div>
        <div className='sign__group sign__group--row'>
          <label className='sign__label'>
            Payment method: <b>Paypal</b>
          </label>
          <span className='sign__text sign__text--small'>
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour, or randomised words which don't look even slightly
            believable.
          </span>
        </div>
      </div>
      {/* end modal info */}
      {/* modal info */}
      <div
        id='modal-info4'
        className='zoom-anim-dialog mfp-hide modal modal--info'>
        <span className='modal__icon'>
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
            <path d='M12,2A10,10,0,1,0,22,12,10.01114,10.01114,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8.00917,8.00917,0,0,1,12,20Zm0-8.5a1,1,0,0,0-1,1v3a1,1,0,0,0,2,0v-3A1,1,0,0,0,12,11.5Zm0-4a1.25,1.25,0,1,0,1.25,1.25A1.25,1.25,0,0,0,12,7.5Z' />
          </svg>
        </span>
        <button className='modal__close' type='button'>
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
            <path d='M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z' />
          </svg>
        </button>
        <h4 className='sign__title'>Example of notification.</h4>
        <div className='sign__group sign__group--row'>
          <label className='sign__label'>Amount to auto-renew:</label>
          <span className='sign__value'>$18.00</span>
        </div>
        <div className='sign__group sign__group--row'>
          <span className='sign__text sign__text--small'>
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour, or randomised words which don't look even slightly
            believable.
          </span>
        </div>
      </div>
      {/* end modal info */}
      {/* modal info */}
      <div
        id='modal-info5'
        className='zoom-anim-dialog mfp-hide modal modal--info'>
        <span className='modal__icon modal__icon--purple'>
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
            <path d='M18,7h-.35A3.45,3.45,0,0,0,18,5.5a3.49,3.49,0,0,0-6-2.44A3.49,3.49,0,0,0,6,5.5,3.45,3.45,0,0,0,6.35,7H6a3,3,0,0,0-3,3v2a1,1,0,0,0,1,1H5v6a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V13h1a1,1,0,0,0,1-1V10A3,3,0,0,0,18,7ZM11,20H8a1,1,0,0,1-1-1V13h4Zm0-9H5V10A1,1,0,0,1,6,9h5Zm0-4H9.5A1.5,1.5,0,1,1,11,5.5Zm2-1.5A1.5,1.5,0,1,1,14.5,7H13ZM17,19a1,1,0,0,1-1,1H13V13h4Zm2-8H13V9h5a1,1,0,0,1,1,1Z' />
          </svg>
        </span>
        <button className='modal__close' type='button'>
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
            <path d='M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z' />
          </svg>
        </button>
        <h4 className='sign__title'>You have received a gift!</h4>
        <div className='sign__group sign__group--row'>
          <span className='sign__text sign__text--small'>
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour, or randomised words which don't look even slightly
            believable.
          </span>
          <span className='sign__text sign__text--small'>
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour, or randomised words which don't look even slightly
            believable.
          </span>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Modal;
